var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "review",
      attrs: {
        title: _vm.editTitle,
        "mask-closable": false,
        draggable: false,
        width: "900",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("Alert", [_vm._v("复检项目选择")]),
      _c("Transfer", {
        attrs: {
          filterable: "",
          data: _vm.data,
          "list-style": _vm.listStyle,
          "target-keys": _vm.targetKeys,
          titles: _vm.titles,
          "render-format": _vm.render,
        },
        on: { "on-change": _vm.handleChange },
      }),
      _vm.loading ? _c("Spin", { attrs: { fix: "" } }) : _vm._e(),
      _c(
        "Row",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "Form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-width": 100,
              },
            },
            [
              _c(
                "FormItem",
                { attrs: { label: "复查原因", prop: "reason" } },
                [
                  _c("Input", {
                    attrs: {
                      type: "textarea",
                      autosize: { maxRows: 3, minRows: 3 },
                    },
                    model: {
                      value: _vm.form.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reason", $$v)
                      },
                      expression: "form.reason",
                    },
                  }),
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: "危害因素", prop: "hazardousFactorsCode" } },
                [
                  _c(
                    "Select",
                    {
                      attrs: { multiple: "", "label-width": "165px" },
                      model: {
                        value: _vm.form.hazardousFactorsCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "hazardousFactorsCode", $$v)
                        },
                        expression: "form.hazardousFactorsCode",
                      },
                    },
                    _vm._l(_vm.hazardousFactorsArr, function (item, i) {
                      return _c("Option", { attrs: { value: item.name } }, [
                        _vm._v(_vm._s(item.name)),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "default" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
          _c(
            "Button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }